import s from './ResultModuleNew.module.scss'
import { ReactComponent as VectorBtn } from '../../../assets/main/Vector.svg'
import { useDispatch, useSelector } from "react-redux"
import { resetStatus } from "../../../store/recognizeSlice"
import { useTranslation } from "react-i18next"

export const ResultModuleNew = () => {
  const { recognizedBirds, spectrogram_legend, spectrogram_anno, language } = useSelector(state => state.recognize)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const birdElements = Object.keys(recognizedBirds).map((birdName) => {

    const value = recognizedBirds[birdName];
    const rangeElements = value.slice(2).map((arr, index) => {
      const [start, end] = arr;
      const rangeText = `${start}s - ${end}s`;
      return <div className={s.segment_time} key={index}>{rangeText}</div>;
    });
    const firstRange = value[1];
    const birdTitle = value[0][language]
    return (
      <div className={s.bird_card} key={birdName}>
        <div>
          <img src={firstRange} alt="" />
        </div>
        <div>
          <div className={s.card_title}>{birdTitle}</div>
          <div className={s.segment_title}>
            {t('difineSegment')}
          </div>

          <div className={s.segment}>{rangeElements}</div>

        </div>



      </div>
    );
  });


  return (
    <div className={s.resultModule}>
      <div className={s.recognitionBox}>
        <button onClick={() => dispatch(resetStatus())} className={s.recognitionButton}>
          {t('opnRecognitionBox')} <VectorBtn className={s.btnBird} /> </button>
      </div>
      <div className={s.spectrogram}>
        <div className={s.legend_body}>
          <img src={spectrogram_legend} alt="" />
        </div>
        <div className={s.anno_body}>
          <img src={spectrogram_anno} alt="" />
        </div>


      </div>
      <div className={s.resultContainer}>
        {birdElements}
      </div>
    </div>
  )
}