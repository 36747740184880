import React from "react";
import s from './Paginator.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { fetchCollection } from "../../store/collectionSlice";


export const Paginator = ({ url }) => {
    const dispatch = useDispatch();
    const { count, currentPage } = useSelector((state) => state.collection);
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(startPage + 4, Math.ceil(count / 10));
  
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  
    return (
      <div className={s.paginator}>
        {currentPage > 1 ? <ArrowBackIos className={s.arrow_paginator} onClick={() => dispatch(fetchCollection(`${url}${1}`))} /> : null}
        {pageNumbers.map((pageNumber) => (
          <span
            onClick={() => dispatch(fetchCollection(`${url}${pageNumber}`))}
            className={currentPage === pageNumber ? s.page_current : s.page_number}
            key={pageNumber}
          >
            {pageNumber}
          </span>
        ))}
        {currentPage !== endPage && count > 10 ? <ArrowForwardIos className={s.arrow_paginator} onClick={() => dispatch(fetchCollection(`${url}${Math.ceil(count / 10)}`))} /> : null}
      </div>
    );
  };