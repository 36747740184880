import React from 'react'
import './Footer.scss'
import {ReactComponent as Telegram} from './telegram.svg'
import {ReactComponent as Linked} from './linked.svg'
import {ReactComponent as FaceBook} from './facebook.svg'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
    const { t } = useTranslation()
    return (
        <div className='footer__container'>
            <div className='footer__bird footer__colomn'>
                <div className='footer__tittle'>
                    {t('footerTitleContacts')}
                </div>
                <div>
                   {t('footerFirstAdress')}
                </div>
                <div>
                    {t('footerSecondAdress')}
                </div>
                <div>
                    {t('footerEmail')} <a href="mailto:homelkv@gmail.com ">homelkv@gmail.com </a>
                </div>
            </div>
            <div className='footer__legal footer__colomn'>
                <div className='footer__tittle'>
                    {t('footerTitleInformation')}
                </div>
                <a href="##">{t('footerAboutProject')}</a>
                <a href="##">{t('footerTermOfUse')}</a>
                <a href="##">{t('footerPrivacyPolicy')}</a>
            </div>
            <div className='footer__join footer__colomn'>
                <div className='footer__tittle'>
                {t('footerTitleOurProject')}
                    </div>
                <a target='blank' href="https://www.corpus.by">corpus.by</a>
                <a target='blank' href="https://www.krokam.com">krokam.com</a>
            </div>
            <div className='footer__follow footer__colomn'>
                <div className='footer__tittle'>
                {t('footerDevelopment')}
                </div>
                <a href="mailto:support@ssrlab.by">support@ssrlab.by</a>
                <a href="https://t.me/bird_sound_labelling_bot">{t('footerTelegramBot')}</a>
                <div className='follow__img'>
                    <Linked />
                    <Telegram />
                    <FaceBook />
                </div>
            </div>
        </div>
    )
}