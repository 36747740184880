import { useEffect, useRef, useState } from 'react';
import i18n from '../../i18n';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'
import s from './SelectLanguage.module.scss'
import { useDispatch } from 'react-redux';
import { changeLanguages } from '../../store/recognizeSlice';

export const SelectLanguage = () => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'BE')
    const [isOpen, setIsOpen] = useState(false)
    const optionBodyRef = useRef(null)
    const dispatch = useDispatch();
    const changeLanguage = (e, ind) => {
        i18n.changeLanguage(e);
        setLanguage(e)
    }

    const closeOptionBody = (event) => {
        if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        localStorage.setItem('language', language)
        document.addEventListener('click', closeOptionBody)
        switch (language) {
            case 'EN':
                dispatch(changeLanguages(0))
                break;
            case 'RU':
                dispatch(changeLanguages(1))
                break;
            case 'BE':
                dispatch(changeLanguages(2))
                break
            default:
                break
        }
        return () => {
            document.removeEventListener('click', closeOptionBody)
        };
    }, [language, dispatch])
    return (
        <div ref={optionBodyRef} className={s.languages_container} onClick={() => setIsOpen(!isOpen)}>
            <div className={s.selected_language}>
                <span>{language} </span>
                <Arrow style={isOpen ? { transform: 'rotate(180deg)' } : null} />
            </div>

            {isOpen ?
                <div className={s.languages}>
                    <div style={language === 'BE' ? { background: '#DAEDFF' } : null} onClick={() => changeLanguage('BE', 2)}>BE</div>
                    <div style={language === 'EN' ? { background: '#DAEDFF' } : null} onClick={() => changeLanguage('EN', 0)}>EN</div>
                    <div style={language === 'RU' ? { background: '#DAEDFF' } : null} onClick={() => changeLanguage('RU', 1)}>RU</div>
                </div> :
                null}
        </div>
    )
}