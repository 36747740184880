import React, { useEffect, useState } from 'react'
import s from './SignIn.module.scss'
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom'
import invis from '../../assets/SignUp/invisible.png'
import vis from '../../assets/SignUp/visible.png'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resetAuthError } from '../../store/authSlice';
import { useTranslation } from 'react-i18next';

export const SignIn = () => {
    const dispatch = useDispatch()
    const { statusLogin, isAuth, error } = useSelector(state => state.auth)
    const [passVis, setPassVis] = useState(false)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const passType = passVis ? 'text' : 'password'
    const passIcon = passVis ? vis : invis
    const initialValues = {
        username: '',
        password: ''
    }
    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('fieldIsRequired'))
        .matches(/^[A-Za-z0-9]+$/, t('onlyLetersAndNumbers')),
        password: Yup.string().required(t('fieldIsRequired')).min(8, t('minSymbols')),
    });
    const handleSubmit = async (values) => {
        dispatch(loginUser(values))
    }

    useEffect(() => {
        dispatch(resetAuthError())
    }, [dispatch])

    useEffect(() => {
        if (isAuth) {
            navigate('/userrecognition')
        }
    }, [isAuth, navigate])
    return (
        <div className={s.wrapper}>
            <div>
                <div className={s.container}>

                    <div className={`${s.content} ${statusLogin === 'loading' ? s.animate : ''}`}>
                        <div className={s.birds}></div>
                        <div className={s.formSignIn}>
                            <div className={s.formTittle}>{t('signIn')}</div>
                            <div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        handleSubmit(values);
                                    }}>
                                    {({ touched, errors }) => (
                                        <Form className={s.form}>
                                            <label htmlFor="username">
                                                {t('signName')}
                                            </label>
                                            <div className={s.inputField}>
                                                <Field
                                                    name="username"
                                                    type="text" />
                                            </div>
                                            {errors.username && touched.username ?
                                                <>
                                                    <div className={s.redLine}></div>
                                                    <div className={s.error}>{errors.username}</div>
                                                </>
                                                : null}
                                            <label htmlFor="password">
                                                {t('signPassword')}
                                            </label>
                                            <div className={s.inputField}>
                                                <Field
                                                    name="password"
                                                    type={passType}
                                                /> <i onClick={() => setPassVis(!passVis)}>
                                                    <img className={s.vis} src={passIcon} alt="" /></i>
                                            </div>
                                            {errors.password && touched.password ?
                                                <>
                                                    <div className={s.redLine}></div>
                                                    <div className={s.error}>{errors.password}</div>
                                                </>
                                                : null}
                                                {error ? <div style={{color: 'red'}}>{error}</div> : null}
                                            <button disabled={statusLogin === 'loading' ? true : false} className={s.signBtn} type="submit">
                                                {t('signIn')}
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className={s.links}>
                                <NavLink to='/forgot'>
                                    {t('forgotYourPassword')}
                                </NavLink>
                                <NavLink to='/signup'>
                                    {t('signUp')}
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={s.upperCloud}></div>
                <div className={s.uuderCloud}></div>
            </div>

        </div>

    )
}