import { useState } from "react"
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow.svg"
import s from './Examples.module.scss'
import example_1 from './Sylviaatricapilla568018.mp3'
import example_2 from './Phylloscopuscollybita90927.mp3'
import example_3 from './Hippolaisicterina477137.mp3'
import { useTranslation } from "react-i18next"

export const Examples = ({changeUrl, url}) => {
    const { t } = useTranslation()
    const [isOpenExaplesItems, setIsOpenExaplesItems] = useState(false)

    const handleClick = async (audio) => {
        const blob = await fetchFile(audio)
        if (blob) {
            changeUrl(audio, blob)
        }
    }
    const fetchFile = async (audio) => {
        const response = await fetch(audio)
        return response.ok ? await response.blob() : null
    }

    return (
        <div className={s.exaples_body}>
            <div onClick={() => setIsOpenExaplesItems(!isOpenExaplesItems)}
                className={s.examples_header} >
                <div>{t('examples')}</div>
                <Arrow />
                {isOpenExaplesItems && <div className={s.examples_items}>
                    <div className={s.example_item} onClick={() => handleClick(example_1)}>
                        <div className={`${s.circle} ${example_1 === url ? s.active : ''}`}></div>
                        <div className={s.text}>test_model_1</div>
                    </div>
                    <div className={s.example_item} onClick={() => handleClick(example_2)}>
                        <div className={`${s.circle} ${example_2 === url ? s.active : ''}`}></div>
                        <div className={s.text}>test_model_2</div>
                    </div>
                    <div className={s.example_item} onClick={() => handleClick(example_3)}>
                        <div className={`${s.circle} ${example_3 === url ? s.active : ''}`}></div>
                        <div className={s.text}>test_model_3</div>
                    </div>
                </div>}
            </div>
        </div>
    )
}