import React from 'react'
import s from './HowToUse.module.scss'
import howToUseIMG from '../../../assets/HowToUse/Photo.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const HowToUse = () => {
    const { isAuth } = useSelector(state => state.auth)
    const { t } = useTranslation();
    return (
        <div className={s.howToUse}>
            <div className={s.howToUse__leftBlock}>
                <div className={s.howToUse__tittle}>
                    {t('howToUseTittle')}
                </div>
                {/* <div className={s.howToUse__text}>
                    the service has a simple intuitive interface that
                    is easy to use and does not require special training
                </div> */}
                <div className={s.howToUse__list}>
                    <ul className={s.howToUse__ul}>
                        <li> {t('howToUseText_1')}</li>
                        <li>{t('howToUseText_2')}</li>
                        <li>{t('howToUseText_3')}</li>
                        <li>{t('howToUseText_4')}</li>
                    </ul>
                </div>
                <div className={s.howToUse__button}>
                    {
                        isAuth &&
                        <div id='try' className={s.try}>
                            <Link to='/userrecognition'> <button>
                                {t('tryIt')}
                            </button></Link>
                        </div>
                    }

                </div>
            </div>
            <div className={s.howToUse__rightBlock}>
                <div className={s.howToUse__img}>
                    <img src={howToUseIMG} alt="" />
                </div>
            </div>
        </div>
    )
}
