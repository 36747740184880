import { Formik, Field, Form } from 'formik';
import React from "react";
import img from '../../../assets/mail/birds.png'
import s from './Mail.module.scss'
import { useTranslation } from 'react-i18next';

export const Mail = () => {
    const { t } = useTranslation()
    return (
        <div className={s.mail}>
            <div className={s.mail__container}>
                <div className={s.mail__visible}>
                    <Formik
                        initialValues={{
                            name: '',
                            company: '',
                            email: '',
                            offers: ''
                        }}
                        onSubmit={(values) => {
                            console.log(values);
                        }}>
                        <Form className={s.mail__form}>
                            <label htmlFor="Name">{t('mailName')}</label>
                            <Field id="Name" name="name"/>

                            <label htmlFor="email">{t('mailEmail')}</label>
                            <Field
                                id="email"
                                name="email"
                                type="email"
                            />
                            <label htmlFor="company">{t('mailCompany')}</label>
                            <Field id="company" name="company"/>

                            <label htmlFor="offers">{t('mailOffers')}</label>
                            <Field name="offers" component="textarea" />

                            <button disabled className={s.mail__btn} type="submit">{t('mailSubmitButton')}</button>
                        </Form>
                    </Formik>
                    <div className={s.mail__content}>
                        <div className={s.mail__tittle}>{t('mailTitle')}</div>
                        <div className={s.mail__text}>
                            {t('mailText')}
                        </div>
                    </div>
                    <div className={s.mail__img}>
                        <img src={img} alt="" />
                    </div>
                </div>

            </div>
            <div className={s.mail__cloud}></div>
        </div>
    )
}