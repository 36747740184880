import React from "react";
import s from './Download.module.scss'
import { useTranslation } from "react-i18next";



export const Download = () => {
    const { t } = useTranslation();
    return (
        <div className={s.body}>
            <div className={s.discription}>
                <div className={s.definition}>{t('definition')}</div>
                <div className={s.buttons}>
                    <button className={s.button}>IOS</button>
                    <button className={s.button}>Android</button>
                </div>
                <div className={s.star_text}>
                    {t('starText')}
                </div>
            </div>
            <div className={s.advertising}>
                <div className={s.title}>MOBILE APP</div>
                <div className={s.advertising__content}>
                    <div className={s.text}>{t('advertisingText')}</div>
                    <div className={s.animate}>
                            <div className={s.soundwaves}>
                                <span className={s.soundwaveone}/>
                                <span className={s.soundwavetwo}/>
                            </div>
                            <div className={s.soundwaves}>
                                <span className={s.soundwaveone}/>
                                <span className={s.soundwavetwo}/>
                            </div>
                            <div className={s.soundwaves}>
                                <span className={s.soundwaveone}/>
                                <span className={s.soundwavetwo}/>
                            </div>
                        </div>
                </div>
            </div>

        </div>

    )
}