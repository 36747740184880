import React, { useState, useEffect, useRef } from 'react'
import s from './HeaderDropDown.module.scss'
import photo from '../../../assets/settings/avatar.png'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Settings } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

export const HeaderDropDownMenu = () => {

    const [dropMenu, setDropMenu] = useState(false)
    const dropMenuRef = useRef(null)
    const [mobileMenu, setMobileMenu] = useState(false)
    const mobileMenuRef = useRef(null)
    const locate = useLocation()
    const path = locate.pathname
    const { userName, avatarUrl, is_staff } = useSelector(state => state.auth)

    const { t } = useTranslation()

    useEffect(() => {
        if (!dropMenu) return

        const handleClick = (e) => {
            if (!dropMenuRef.current) return
            if (!dropMenuRef.current.contains(e.target)) {
                setDropMenu(false)
            }
        }
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [dropMenu])
    useEffect(() => {
        if (!mobileMenu) return

        const handleClick = (e) => {
            if (!mobileMenuRef.current) return
            if (!mobileMenuRef.current.contains(e.target)) {
                setMobileMenu(false)
            }
        }
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [mobileMenu])
    return (
        <>
            <div className={s.header_container}>
                <div ref={dropMenuRef} className={s.menu_container}>
                    <div onClick={() => setDropMenu(!dropMenu)} className={dropMenu ? s.menu_vis : s.menu}>
                        <div>{t('menu')}</div>
                        <div className={dropMenu ? s.arrow_vis : s.arrow}>
                            <div className={dropMenu ? s.arrow_up : s.arrow_down}></div>
                        </div>
                    </div>
                    <div className={dropMenu ? s.menu_drop_vis : s.menu_drop}>
                        <NavLink onClick={() => setDropMenu(false)} to='/'>
                            <div className={s.drop_item}>
                                {t('mainPage')}
                            </div>
                        </NavLink>
                        {path === '/userrecognition' && is_staff && <NavLink onClick={() => setDropMenu(false)}
                            to={'/collectiontable'}>
                            <div className={s.drop_item}>
                                {t('collection')}
                            </div>
                        </NavLink>}
                        {path !== '/userrecognition' && <NavLink onClick={() => setDropMenu(false)}
                            to={'/userrecognition'}>
                            <div className={s.drop_item}>
                                {t('recognitionTitle')}
                            </div>
                        </NavLink>
}
                    </div>
                </div>

                <div className={s.userHi}>
                    <div>
                        <img className={s.user_logo} 
                        src={avatarUrl ? `https://bird-sounds-database.ssrlab.by${avatarUrl}` : photo } alt="" />
                    </div>
                    <div className={s.user_text}> {t('hello')} {userName}!</div>
                    <div className={s.user_settings}>
                        <NavLink onClick={() => setDropMenu(false)}
                            to='/settingspage'>
                                <Settings className={s.setting_icon} />
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className={mobileMenu ? s.bar__background : s.mobile__menu}>
                <div ref={mobileMenuRef} className={s.mobile__menu}>
                    <div className={!mobileMenu ? s.burger__menu : s.burger__menu_invis} onClick={() => setMobileMenu(true)}>
                        <i className='material-icons notranslate'>
                            menu
                        </i>
                    </div>

                    <div className={mobileMenu ? s.side__bar : s.side__bar_invis}>
                        <i onClick={() => setMobileMenu(false)} className='material-icons'>close</i>
                        <div className={s.side__user}>
                            <div>
                                <img className={s.user_logo} 
                                src={avatarUrl ? `https://bird-sounds-database.ssrlab.by${avatarUrl}` : photo } alt="" />
                            </div>
                            <div className={s.user_text}> {t('hello')} {userName}!</div>
                        </div>
                        {is_staff && <NavLink onClick={() => setMobileMenu(false)} to='/collectiontable'>
                            <div className={s.drop_item}>
                                {t('collection')}
                            </div>
                        </NavLink>}
                        <NavLink onClick={() => setMobileMenu(false)} to='/userrecognition'>
                            <div className={s.drop_item}>
                                {t('recognitionTitle')}
                            </div>
                        </NavLink>
                        <NavLink onClick={() => setMobileMenu(false)} to='/settingspage'>
                            <div className={s.drop_item}>
                                My account
                            </div>
                        </NavLink>
                        <NavLink onClick={() => setDropMenu(false)} to='/'>
                            <div className={s.drop_item}>
                                {t('mainPage')}
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>

        </>
    )
}